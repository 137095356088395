<div class="modal-wrapper p-6" [formGroup]="editProfileForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">Edit Basic Details</h2>
      <p class="textXS mt-2 text">Update your profile</p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="content-wrapper my-4">
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Name <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appCapitalizeFirstLetter
          matInput
          placeholder="Enter here"
          formControlName="name"
        />
      </mat-form-field>
      <div
        *ngIf="
          editProfileForm.get('name')?.touched ||
          editProfileForm.get('name')?.dirty
        "
      >
        <span
          class="required-star"
          *ngIf="editProfileForm.get('name')?.hasError('required')"
        >
          Field is required.
        </span>
        <div
          class="required-star mt-1"
          *ngIf="
            !editProfileForm.get('name')?.hasError('required') &&
            editProfileForm.get('name')?.hasError('whitespace')
          "
        >
          Field cannot be empty or contain only whitespace.
        </div>
      </div>
    </div>

    <div class="input-wrapper mb-6">
      <div class="d-flex align-center mb-2">
        <p class="mat-body-1 mb-0 font-weight-500 input-label">
          Email <span class="required-star"> * </span>
        </p>
      </div>
      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        style="cursor: not-allowed"
      >
        <input matInput formControlName="email" style="cursor: not-allowed" />
      </mat-form-field>
    </div>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="'Save'"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (click)="isButtonDisabled ? null : onSubmit()"
    ></sft-button>
  </div>
</div>
