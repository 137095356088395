<div class="modal-wrapper p-6">
  <form [formGroup]="passwordForm">
    <div class="modal-header d-flex justify-between">
      <div>
        <h2 class="mat-subtitle-1 mb-0 text">Reset Password</h2>
        <p class="textXS mt-2 text">Change your password</p>
      </div>
      <mat-icon class="pointer" (click)="close()">close</mat-icon>
    </div>
    <div class="content-wrapper my-4">
      <!-- Current Password Field -->
      <div class="d-flex align-center mb-2">
        <p class="mat-body-1 mb-0 font-weight-500 input-label">
          Current Password <span class="required-star"> * </span>
        </p>
      </div>
      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="mb-4"
      >
        <input
          matInput
          placeholder="Enter here"
          [type]="currentPasswordHide ? 'password' : 'text'"
          formControlName="currentPassword"
          id="currentPassword"
          name="currentPassword"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="currentPasswordHide = !currentPasswordHide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="currentPasswordHide"
        >
          <mat-icon>{{
            currentPasswordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>
      <div *ngIf="errorMessage" class="required-star mb-2">
        {{ errorMessage }}
      </div>

      <!-- New Password Field -->
      <div class="d-flex align-center mb-2">
        <p class="mat-body-1 mb-0 font-weight-500 input-label">
          New Password <span class="required-star"> * </span>
        </p>
      </div>
      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="mb-4"
      >
        <input
          matInput
          placeholder="Enter here"
          [type]="hide ? 'password' : 'text'"
          formControlName="newPassword"
          id="newPassword"
          name="newPassword"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>

      <!-- New Password Validation Errors -->
      <div class="mb-2">
        <div
          *ngIf="lowercaseError && passwordForm.get('newPassword')?.dirty"
          class="required-star"
        >
          Password must contain at least one lowercase character.
        </div>
        <div
          *ngIf="lengthError && passwordForm.get('newPassword')?.dirty"
          class="required-star"
        >
          Be at least 8 characters long.
        </div>
        <div
          *ngIf="symbolError && passwordForm.get('newPassword')?.dirty"
          class="required-star"
        >
          Password must contain at least one number, symbol, or whitespace
          character.
        </div>
      </div>

      <!-- Re-enter Password Field -->
      <div class="d-flex align-center mb-2">
        <p class="mat-body-1 mb-0 font-weight-500 input-label">
          Re-Enter Password <span class="required-star"> * </span>
        </p>
      </div>
      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="mb-4"
      >
        <input
          matInput
          placeholder="Enter here"
          [type]="reEnterPasswordHide ? 'password' : 'text'"
          formControlName="reEnterPassword"
          id="reEnterPassword"
          name="reEnterPassword"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="reEnterPasswordHide = !reEnterPasswordHide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="reEnterPasswordHide"
        >
          <mat-icon>{{
            reEnterPasswordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>

      <!-- Password Match Error -->
      <div
        *ngIf="
          passwordForm.hasError('passwordsNotMatch') &&
          passwordForm.get('reEnterPassword')?.dirty
        "
        class="required-star mb-2"
      >
        Passwords do not match.
      </div>

      <!-- Password Requirements -->
      <div class="content-wrapper mb-6">
        <h3 class="grey-text mat-subtitle-2 fw-500">Password Requirements:</h3>
        <ol>
          <li class="list-disc grey-text textS ml-5">
            Minimum 8 characters long - the more, the better
          </li>
          <li class="list-disc grey-text textS ml-5">
            At least one lowercase character
          </li>
          <li class="list-disc grey-text textS ml-5">
            At least one number, symbol, or whitespace character
          </li>
        </ol>
      </div>
    </div>

    <!-- Save Button -->
    <div class="modal-footer mt-2 d-flex justify-end">
      <sft-button
        [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
        [label]="'Save'"
        [color]="'primary'"
        [variant]="'flat'"
        [disable]="isButtonDisabled"
        [size]="'large'"
        (click)="isButtonDisabled ? null : onChange()"
      ></sft-button>
    </div>
  </form>
</div>
